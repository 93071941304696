import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled, { CSSProperties } from "styled-components/macro";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { usePopper } from "react-popper";
import { useHover } from "react-use";
import { useIsLarge } from "../../utils/hooks";

const Wrapper = styled.div`
  font-size: 18px;
  margin-left: 3px;
  display: inline-block;
`;

const Tooltip = styled.div`
  width: 300px;
  opacity: 0.9;
  background: #222;
  border: 1px solid black;
  border-radius: 2px;
  color: white;
  font-size: 12px;
  padding: 5px;
  z-index: 100000;
`;

const Arrow = styled.div`
  visibility: hidden;
  position: relative;

  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  ${Tooltip}[data-popper-placement^="top"] > & {
    bottom: -4px;
  }

  ${Tooltip}[data-popper-placement^="bottom"] > & {
    top: -4px;
  }

  ${Tooltip}[data-popper-placement^="left"] > & {
    right: -4px;
  }

  ${Tooltip}[data-popper-placement^="right"] > & {
    left: -4px;
  }
`;

type InlineHelpProps = React.PropsWithChildren<{ style?: CSSProperties; className?: string }>;

const InlineHelp = ({ children, style, className }: InlineHelpProps) => {
  const isLarge = useIsLarge();
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    placement: isLarge ? "right" : "top",
    modifiers: [
      { name: "arrow", options: { element: arrowElement, padding: 5 } },
      { name: "offset", options: { offset: [0, 8] } },
    ],
  });

  const [hoverable, isHover] = useHover(() => <span ref={setReferenceElement} children={<IoIosHelpCircleOutline />} />);

  return (
    <Wrapper style={style} className={className} onClick={(event) => !isLarge && event.preventDefault()}>
      {hoverable}
      {isHover &&
        ReactDOM.createPortal(
          <Tooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {children}
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </Tooltip>,
          document.body
        )}
    </Wrapper>
  );
};

export default InlineHelp;

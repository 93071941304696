import React from "react";
import PropTypes from "prop-types";
import fullLogo from './logo/full.svg'
import responsiveLogo from './logo/responsive.svg'

const Logo = props => (
  <img alt="Roombelt logo" src={props.withName ? fullLogo : responsiveLogo}
    onClick={props.onClick}
    className={props.className}
    style={{
      cursor: props.onClick ? "pointer" : "inherit",
      height: props.size,
      textAlign: "center",
      ...props.style
    }}
  />
);

Logo.propTypes = {
  withName: PropTypes.bool,
  color: PropTypes.string
};

Logo.defaultProps = {
  color: "black"
};

export default Logo;

import { useEffect } from "react";

export function useBlockZoomOnDoubleTap() {
  useEffect(() => {
    function listener(event: TouchEvent) {
      const interactiveEl = (event.target as HTMLElement)?.closest?.("input, button, a");
      if (!interactiveEl) {
        event.preventDefault();
      }
    }

    document.addEventListener("touchend", listener, false);
    return () => document.removeEventListener("touchend", listener, false);
  });
}

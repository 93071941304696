import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components/macro";
import Moment from "@/moment";

const blinkAnimation = keyframes`
  from { opacity: 1 }
  60% { opacity: 1 }
  61% { opacity: 0 }
  to { opacity: 0 }
`;

const TimeSeparator = styled.span`
  animation: ${blinkAnimation} 1s linear 0s infinite alternate;
  ${(props) => (!props.blinking ? "animation: none;" : "")};
`;

const Time = (props) => {
  const { hour, minutes, suffix } = formatHour(props.timestamp, props.ampm);

  return (
    <span className={`time custom-time ${props.className ?? ""}`}>
      {hour}
      <TimeSeparator blinking={props.blinking}>:</TimeSeparator>
      {minutes}
      <span className="time-suffix custom-time-suffix" style={{ fontSize: props.smallSuffix ? "0.5em" : "1em" }}>
        {suffix}
      </span>
    </span>
  );
};

export function formatHour(timestamp, isAmPm) {
  const pad = (number) => number.toString().padStart(2, "0");

  const hour24h = new Moment(timestamp).hour();
  const hour12h = hour24h % 12 || 12;
  const suffix12h = hour24h >= 12 && hour24h < 24 ? "pm" : "am";

  const hour = pad(isAmPm ? hour12h : hour24h);
  const minutes = pad(new Moment(timestamp).minute());
  const suffix = isAmPm ? suffix12h : "";

  return { hour, minutes, suffix };
}

Time.propTypes = {
  blinking: PropTypes.bool,
  ampm: PropTypes.bool,
  smallSuffix: PropTypes.bool,
  timestamp: PropTypes.number,
  className: PropTypes.string,
};

export default Time;

import i18next from "i18next";
import { getLanguage, setLanguage, setTimeZone, getTimeZone } from "@/services/persistent-store";

const context = require.context("./", false, /\.json$/);
export const translations = context.keys().reduce((acc, file) => ({ ...acc, [context(file).key]: context(file) }), {});

export const changeLocale = (language, timeZone) => {
  let isChanged = false;
  if (timeZone !== getTimeZone()) {
    setTimeZone(timeZone);
    isChanged = true;
  }

  if (language && language !== getLanguage()) {
    setLanguage(language);
    isChanged = true;
  }

  if (isChanged) {
    window.location.reload();
  }
};

export default function i18n() {
  return new Promise((resolve) => {
    i18next
      .use({ type: "backend", read: (language, namespace, callback) => callback(null, translations[language]) })
      .init({ lng: getLanguage(), fallbackLng: "en-US" }, () => resolve());
  });
}

import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import queryString from "query-string";

import { Alert, Button, PageLoaded } from "@/theme";
import CardAndFooterLayout from "@/theme/layouts/CardAndFooter";
import { useBlockZoomOnDoubleTap } from "@/utils/hacks";
import { prettyFormatList } from "@/utils/formatting";
import { getAuthUrls } from "@/services/api";

import Logo from "../Logo";
import Footer from "../Footer";

import buttonGoogle from "./logo-google.svg";
import buttonMicrosoft from "./logo-microsoft.svg";
import buttonZoho from "./logo-zoho.ico";

export default function Login() {
  useBlockZoomOnDoubleTap();

  const [authUrl, setAuthUrl] = useState({ google: "/", office365: "/" });

  useEffect(() => {
    getAuthUrls().then((authUrls) => setAuthUrl(authUrls));
  }, []);

  const query = queryString.parse(window.location.search);

  return (
    <CardAndFooterLayout footer={<Footer />}>
      <PageLoaded />
      <AppLogo withName />
      <AppMenu>
        {query.error === "not_allowed_email" && (
          <Alert error style={{ maxWidth: 300 }}>
            <strong>Access denied</strong>
            <br />
            Your email address is not allowed to access this Roombelt installation. Please ask the Roombelt
            administrator to grant you access.
          </Alert>
        )}
        {query.error === "office365_login_error" && (
          <Alert error style={{ maxWidth: 300 }}>
            <strong>Authentication error</strong>
            <br />
            If you've just signed up for the first time please wait a few minutes and try again.
            <br />
            Please ensure that you use a school or work Office365 account and that it has a license for Exchange Online
            (Outlook).
          </Alert>
        )}
        {query.error === "google_login_error" && (
          <Alert error style={{ maxWidth: 300 }}>
            <strong>Authentication error</strong>
            <br />
            We can't access your calendar data.
            <br />
            Please log in again and grant all the requested permissions.
          </Alert>
        )}
        {query.error === "zoho_login_error" && (
          <Alert error style={{ maxWidth: 300 }}>
            <strong>Authentication error</strong>
            <br />
            Please ensure that you use a Zoho account with access to{" "}
            <a
              href="https://help.zoho.com/portal/en/kb/calendar/resource-booking/articles/resource-booking"
              target="_blank"
              rel="noreferrer noopener"
            >
              Resource Booking
            </a>
            .
          </Alert>
        )}
        {query.error === "not_in_organization" && (
          <Alert error style={{ maxWidth: 300 }}>
            You have logged in as <strong>{query.email}</strong> and you are missing access to your organization in
            Roombelt.
            <br />
            <br />
            Contact {prettyFormatList(query.admins?.split(","))} to grant you access.
          </Alert>
        )}
        {query.info === "logout" && (
          <Alert info style={{ maxWidth: 300 }}>
            <strong>You've successfully logged out</strong>
          </Alert>
        )}
        {query.info === "auto-logout" && (
          <Alert info style={{ maxWidth: 300 }}>
            <strong>You've been automatically logged out</strong>
          </Alert>
        )}
        {authUrl.google && (
          <MenuButton block href={authUrl.google} white data-test-id="login-button-google">
            <LogoImg alt="Google logo" src={buttonGoogle} />
            <span>Log in with Google</span>
          </MenuButton>
        )}
        {authUrl.office365 && (
          <MenuButton block href={authUrl.office365} white data-test-id="login-button-office365">
            <LogoImg alt="Microsoft logo" src={buttonMicrosoft} />
            <span>Log in with Microsoft</span>
          </MenuButton>
        )}
        {authUrl.zoho && (
          <MenuButton block href={authUrl.zoho} white>
            <LogoImg alt="Zoho logo" src={buttonZoho} />
            <span>Log in with Zoho</span>
          </MenuButton>
        )}
        <MenuButton block to={"/device"} white as={SimpleLink}>
          Register device
        </MenuButton>
      </AppMenu>
    </CardAndFooterLayout>
  );
}

const AppLogo = styled(Logo)`
  display: block;
  margin: 10px auto 30px auto;
  height: 30px;
`;

const AppMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: calc(100vw - 100px);
  max-width: 350px;
`;

const MenuButton = styled(Button)`
  margin: 10px 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
`;

const LogoImg = styled.img`
  margin-right: 10px;
  height: 20px;
  margin-bottom: 2px;
`;

const SimpleLink = ({ to, className, children }) => <Link to={to} className={className} children={children} />;

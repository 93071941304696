import styled from "styled-components/macro";

import colors from "../colors";

const StatusIcon = styled.span<{ primary?: boolean, success?: boolean, warning?: boolean, danger?: boolean; info?:boolean }>`
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 50%;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  margin-right: 0.375rem;
  vertical-align: middle;

  background-color: ${props => {
    // @ts-ignore
    const variant = Object.keys(colors.variants).find(variantName => props[variantName]);
    // @ts-ignore
    return colors.variants[variant || "success"];
  }};
`;

export default StatusIcon;

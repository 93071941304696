import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import styled, { css } from "styled-components/macro";
import colors from "@/theme/colors";
import PropTypes from "prop-types";
import Modal from "@/theme/components/Modal";
import { mobile } from "../selectors";

const ModalStyled = styled(Modal)`
  ${mobile} {
    padding: 0;
  }
`;

const Header = styled.div`
  background: ${colors.primary};
  color: white;
  padding: 30px;
  font-weight: bold;

  ${mobile} {
    padding: 15px 30px;
  }
`;

const Content = styled.div`
  padding: 10px 30px;
  ${(props) =>
    props.scrollable &&
    css`
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    `}

  ${(props) =>
    props.isLoading &&
    css`
      filter: blur(3px);
      opacity: 0.8;
      pointer-events: none;
    `}

  ${mobile} {
    max-height: none;
    height: ${(props) => props.windowHeight - 118}px;
  }
`;

function BlueModal(props) {
  const height = useHeightOnOpen(props.visible);
  const contentRef = useRef();

  useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [props.visible]);

  return (
    <ModalStyled
      visible={props.visible}
      compact={true}
      wide={props.wide}
      fullWidth={props.fullWidth}
      footer={props.footer}
      header={null}
      title={props.title}
    >
      <Header aria-hidden={true}>{props.title}</Header>
      <Content
        ref={contentRef}
        scrollable={props.scrollable}
        style={props.style}
        windowHeight={height}
        isLoading={props.isLoading}
      >
        {props.children}
      </Content>
    </ModalStyled>
  );
}

BlueModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  footer: PropTypes.node,
  wide: PropTypes.bool,
  fullWidth: PropTypes.bool,
  scrollable: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
};

function useHeightOnOpen(isOpen) {
  const [height, setHeight] = useState(window.innerHeight);
  const { width } = useWindowSize();

  useEffect(() => {
    setHeight(window.innerHeight);
  }, [isOpen, width]);

  return height;
}
export default BlueModal;

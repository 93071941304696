import { getBackgroundImage } from "@/services/persistent-store";

const defaultColors = {
  foreground: {
    white: "#FFFFFF",
    gray: "#FAFAFA",
  },
  background: {
    header: "#18181A",
    content: "#333333",
    footer: "#424242",
    todayScheduleEvent: "#222",
    listRow: "rgb(66, 66, 66)",
    image: false,
  },
  button: {
    border: "#606060",
    background: "#606060",
    alwaysShowBackground: false,
  },
  dashboard: {
    header: "transparent",
  },
  success: "#54BB84",
  error: "#FF3665",
  warning: "#ff984c",
  info: "#4680bb",
};

const photoColors = {
  foreground: {
    white: "#FFFFFF",
    gray: "#FAFAFA",
  },
  background: {
    header: "transparent",
    content: "rgba(0, 0,0,0.5)",
    footer: "rgba(0, 0,0,0.3)",
    listRow: "rgba(0, 0,0,0.5)",
    image: `url(${getBackgroundImage()})`,
  },
  button: {
    border: "transparent",
    background: "rgba(0,0,0, 0.7)",
    alwaysShowBackground: true,
  },
  success: "#54BB84",
  error: "#FF3665",
  warning: "#ff984c",
  info: "#4680bb",
  dashboard: {
    header: "transparent",
  },
};

export default getBackgroundImage() ? photoColors : defaultColors;

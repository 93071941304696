import React from "react";
import Button from "./Button";
import Loader from "./Loader";

const size = "calc(1em - 2px)";

export default function LoaderButton({ isLoading, children, ...props }) {
  return (
    <Button {...props} disabled={props.disabled || isLoading}>
      {isLoading ? <Loader white={props.danger || props.primary} style={{ height: size, width: size }} /> : children}
    </Button>
  );
}

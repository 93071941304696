import React, { lazy, Suspense } from "react";
import i18next from "i18next";
import { Route, Routes } from "react-router-dom";
import { IconContext } from "react-icons";

import FatalError from "@/theme/layouts/FatalError";
import LoginApp from "@/apps/login/Login";
import { canAccessBenchmarkSite, canAccessRoombeltBackend } from "@/services/api";

const AdminApp = lazy(() => import("@/apps/admin"));
const DeviceApp = lazy(() => import("@/apps/device"));

export default class Router extends React.PureComponent {
  state = { isConfirmedOnline: false, canAccessBenchmarkSite: false, hasFirstOnlineCheckBeenDone: false };

  async componentDidMount() {
    while (!(await canAccessRoombeltBackend())) {
      this.setState({ hasFirstOnlineCheckBeenDone: true, canAccessBenchmarkSite: await canAccessBenchmarkSite() });
      await new Promise((res) => setTimeout(res, 5000));
    }

    this.setState({ hasFirstOnlineCheckBeenDone: true, isConfirmedOnline: true, canAccessBenchmarkSite: true });
  }

  render() {
    if (!this.state.hasFirstOnlineCheckBeenDone) {
      return null;
    }

    if (!this.state.isConfirmedOnline) {
      const message =
        this.state.canAccessBenchmarkSite === false ? i18next.t("errors.unable-to-connect-to-benchmark") : null;
      return <FatalError title={i18next.t("errors.unable-to-connect-server")} message={message} />;
    }

    return (
      <Suspense fallback={<div />}>
        <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
          <Routes>
            <Route path={"/admin"} element={<AdminApp />} />
            <Route path={"/device"} element={<DeviceApp />} />
            <Route path={"/devices"} element={<DeviceApp />} />
            <Route path={"*"} element={<LoginApp />} />
          </Routes>
        </IconContext.Provider>
      </Suspense>
    );
  }
}

import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { getTimeZone } from "./services/persistent-store";

import "moment/locale/de";
import "moment/locale/da";
import "moment/locale/fr";
import "moment/locale/pl";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/sv";
import "moment/locale/en-gb";

const tz = getTimeZone();
if (tz) {
  moment.tz.setDefault(tz);
}

export default moment;

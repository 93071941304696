import { useCallback, useLayoutEffect, useRef } from "react";
import { useMedia } from "react-use";

export function useEvent<T extends (...args: any[]) => any>(handler: T) {
  const handlerRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    const fn = handlerRef.current;
    return fn?.(...args);
  }, []);
}

export const useIsLarge = () => useMedia("(min-width: 768px)");

import React from "react";
import styled, { css } from "styled-components/macro";
import colors from "./colors";

const ButtonWithTouchSupport = ({ onClick, subtle, error, success, disabled, white, ...props }) => (
  <button
    onTouchStart={() => (ButtonWithTouchSupport.isTouchModeEnabled = true)}
    onTouchEnd={(event) => !disabled && onClick(event)}
    onClick={(event) => {
      if (!ButtonWithTouchSupport.isTouchModeEnabled && !disabled && onClick) onClick(event);
      ButtonWithTouchSupport.isTouchModeEnabled = false;
    }}
    {...props}
    disabled={disabled}
    className={
      "button custom-button " +
      (disabled ? " disabled custom-disabled " : "") +
      (success ? " success custom-success " : "") +
      (error ? " error custom-error " : "") +
      (subtle ? " subtle custom-subtle " : "") +
      props.className
    }
  />
);

const backgroundCss = css`
  background: ${colors.button.background};
  ${(props) => props.success && `background: ${colors.success}`};
  ${(props) => props.error && `background: ${colors.error}`};
`;

export default styled(ButtonWithTouchSupport)`
  min-width: 5em;
  padding: 0.3em;
  text-align: center;
  white-space: nowrap;

  color: ${(props) => (props.disabled ? colors.foreground.gray : colors.foreground.white)};
  outline: none;

  font: inherit;
  font-size: 1em;
  border-radius: 0.2em;
  border: 0.1em solid ${colors.button.border};
  background: transparent;
  margin-right: 0.5em;
  user-select: none;

  ${colors.button.alwaysShowBackground && backgroundCss} :hover {
    ${(props) => !props.disabled && backgroundCss}
    ${(props) => !props.disabled && "cursor: pointer"}
  }

  ${(props) =>
    props.disabled &&
    css`
      ${colors.button.alwaysShowBackground && "background: rgba(255, 255, 255, 0.1)"};
      opacity: 0.9;
    `};

  ${(props) => props.success && `border-color: ${colors.success}`};
  ${(props) => props.error && `border-color: ${colors.error}`};
  ${(props) => props.warning && `border-color: ${colors.warning}`};
  ${(props) => props.info && `border-color: ${colors.info}`};

  ${(props) =>
    props.subtle &&
    css`
      border: none;
    `};
`;
